import React from 'react'
import {Deutsch, English, Type, LinkABOUT, Page, Back_To_All_Projects, SiteTitle} from '../../components/linksetc.js'
import Layout from '../../components/layout'

import img2 from "../../images/kilokilo/2.jpg"
import img3 from "../../images/kilokilo/3.jpg"
import img4 from "../../images/kilokilo/4.jpg"
import img5 from "../../images/kilokilo/5.jpg"
import img6 from "../../images/kilokilo/6.jpg"


// for the scrolldown
var Scroll  = require('react-scroll');
var ScrollLink       = Scroll.Link;
var Element    = Scroll.Element;




export default ({ location }) => (
  <Layout>

      <SiteTitle string="Blackest digital agency" />


      <Page location={location}>




      <div className="project_description">

        <English Language={location.search}>
              <Type>
                    <a href="http://www.kilokilo.ch" target="_blank">KiloKilo</a> is the best and blackest digital agency in Zürich and beyond.
                    <p/>
                    <LinkABOUT location={location}>I</LinkABOUT> wrote their website in English and German.
                    <p/>
                    <ScrollLink className="ScrollLink" to="test1" smooth={true} duration={1000}>↓</ScrollLink>


              </Type>
        </English>




         <Deutsch Language={location.search}>
              <Type>


                     <a href="http://www.kilokilo.ch" target="_blank">KiloKilo</a> ist die beste und schwärzeste Digitalagentur in Zürich.
                    <p/>
                    <LinkABOUT location={location}>Ich</LinkABOUT> schrieb ihre Webseite auf Deutsch und Englisch.
                    <p/>
                    <ScrollLink className="ScrollLink" to="test1" smooth={true} duration={1000}>↓</ScrollLink>


              </Type>
        </Deutsch>



    </div>


    <Element name="test1"></Element>


      <img src={img3}/>







      <div className="comment">
        <English Language={location.search}>


              <Type>
              In their office, everything must be square and black. I had to find a verbal identity that would reflect this design neurosis.
              </Type>

        </English>



         <Deutsch Language={location.search}>
              <Type>

                In ihrem Büro muss alles viereckig und schwarz sein. Ich entwickelte eine verbale Identität, welche diese Designneurose widerspiegelt.

              </Type>
        </Deutsch>
        
      </div>



      <img src={img2}/>



      <div className="comment">
        <English Language={location.search}>
              <Type>

              Their code is blazing fast and cutting-edge. The language reflected this with minimalism and a modern register.

              </Type>
        </English>



         <Deutsch Language={location.search}>
              <Type>

                Ihr Code ist blitzschnell und immer auf dem neuesten Stand. Die Sprache bildete dies mit Minimalismus und einer modernen Wortwahl ab.

              </Type>
        </Deutsch>
      </div>





      <img src={img4}/>


      <div className="comment">
        <English Language={location.search}>
              <Type>

              Finding the right tone of voice was a matter of striking the right balance between confidence and subtlety.

              </Type>
        </English>


         <Deutsch Language={location.search}>
              <Type>

                Die richtige Tonalität musste die Balance zwischen Selbstvertrauen und Subtilität finden.

              </Type>
        </Deutsch>
        
      </div>


      <img src={img5}/>



      <div className="comment">
        <English Language={location.search}>
              <Type>

              Due to the nature of their work, project descriptions and employee biographies always told a story enriched by technological complexity.

              </Type>
        </English>


         <Deutsch Language={location.search}>
              <Type>

                Aufgrund der Natur ihrer Projekte erzählten diese und auch die Teambiographien immer eine Geschichte angereichert mit technologischen Komplexitäten.

              </Type>
        </Deutsch>

      </div>


      <img src={img6}/>




<Back_To_All_Projects location={location} />



      </Page>
    </Layout>

)
